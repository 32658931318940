import axios from '@axios'

export default {
  namespaced: true,
  state: {
    // option: {
    //   course: [],
    //   major: [],
    //   level: [],
    //   status: []
    // }
  },
  getters: {},
  mutations: {},
  actions: {
    findOne(ctx, payload) {
      const { id } = payload
      return new Promise((resolve, reject) => {
        axios
          .get(`/student/findOne/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    findAll(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/student/findAll', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    create(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/student/create', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    update(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/student/update', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteOne(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/student/deleteOne', { data: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteAll(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/student/deleteAll', { data: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
