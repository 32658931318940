import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'http://localhost:3000/api',
  baseURL: 'https://ncapi.ongkharak.ac.th/api',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  headers: {
    'Content-Type': 'application/json',
    // You can add other headers here if needed
    // 'X-Custom-Header': 'foobar'
  }
})

Vue.prototype.$http = axiosIns

export default axiosIns
