import { DashboardGroup, AuthGroup, ManageStudentGroup, SettingDataGroup } from '../resource';

export const normalOfficerAbility = [
  { action: 'read', subject: AuthGroup.header },
  // DashboardGroup 
  { action: 'read', subject: DashboardGroup.child.ongkharak },
  // ManageStudentGroup 
  { action: ['read', 'create', 'update'], subject: ManageStudentGroup.child.checking },
  // { action: ['read', 'create', 'update'], subject: ManageStudentGroup.child.report },

  // SettingDataGroup 
]

export default normalOfficerAbility


