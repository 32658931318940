const AuthGroup = {
  header: "Auth",
}

const DashboardGroup = {
   header: "dashboard",
   child: {
    ongkharak: "dashboard-ongkharak",
   }
}

const ManageStudentGroup = {
  header: "manageStudent",
  child: {
    checking: "manageStudent-checking",
    report: "manageStudent-report"
  }
}

const SettingDataGroup = {
  header: "settingData",
  child: {
    student: "settingData-student",
    user: "settingData-user",
  }
}

export {
  AuthGroup, 
  DashboardGroup,
  ManageStudentGroup,
  SettingDataGroup
}