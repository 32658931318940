import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import manageStudentStoreModule from '@/views/pages/manage-student/manageStudentStoreModule'
import studentStoreModule from '@/views/pages/setting-data/student/studentStoreModule'
import userStoreModule from '@/views/pages/setting-data/user/userStoreModule'

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    "manage-student": manageStudentStoreModule,
    "student": studentStoreModule,
    "user": userStoreModule
  },
  strict: process.env.DEV,
})
