import ability from './ability'

// export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))
export const canNavigate = to => to.matched.some(route => {
  // const able = ability.can(route.meta.action || 'read', route.meta.resource)
  const able = ability.can(route.meta.action || 'read', route.meta.resource)

  // console.log("able ",able, route.meta.action, route.meta.resource);
// console.log("rule",ability.rules)

  return able
})


export const _ = undefined
