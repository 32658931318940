import axios from '@axios'

export default {
  namespaced: true,
  state: {
    // option: {
    //   course: [],
    //   major: [],
    //   level: [],
    //   status: []
    // }
  },
  getters: {},
  mutations: {
    // SET_OPTION(state, data) {
    //   state.option = data;
    // }
  },
  actions: {
    /* api/checkStudent */
    findOneStudent(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/checkStudent/student', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    findStudents(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/checkStudent/students', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkStudent(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/checkStudent/checked', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    studentCount(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/checkStudent/studentCount')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDailyreport(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/checkStudent/getDailyreport', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
     getTermreport(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/checkStudent/getTermreport', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    resetReport(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/checkStudent/resetReport', { data: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
   getCourse(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/option/getCourse')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
   getMajor(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/option/getMajor')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getLevel(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/option/getLevel')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getStatus(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/option/getStatus')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
