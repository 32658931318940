import axios from '@axios'

export default {
  namespaced: true,
  state: { },
  getters: {},
  mutations: {},
  actions: {
    findAll(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users/findAll', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    create(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/users/create', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    update(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/users/update', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteOne(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/users/deleteOne', { data: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
