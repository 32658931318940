import { DashboardGroup } from '@/libs/acl/resource'


export default [
  {
    path: '/dashboard/ongkharak',
    name: DashboardGroup.child.ongkharak,
    component: () => import('@/views/dashboard/ongkharak/Index.vue'),
    meta: {
      resource: DashboardGroup.child.ongkharak,
      action: 'read',
    },
  },
]
