import adminAbility from './admin';
import normalOfficerAbility from './normalOfficer';
import userAbility from './user';



export const useRoleACL = (roleName) => {
  let ability = userAbility

  switch (roleName) {
    case "admin":
      ability = adminAbility
      break;
    case "normalOfficer":
      ability = normalOfficerAbility
      break;
    default:
      ability = userAbility
      break;
  }

  return ability
}
